import { Controller } from "stimulus"

const RADIO_SELECTION_TOGGLE = 'radio-selection-toggle'

export default class extends Controller {
  static targets = ['element', 'input']

  set_state(selection) {
    if (selection == 'sepa') {
      this.elementTarget.classList.remove('hide')
    } else {
      this.elementTarget.classList.add('hide')
    }
  }

  connect() {
    let checkedInput = this.inputTargets.find(input => input.checked);

    this.set_state(checkedInput ? checkedInput.value : 'none');
  }

  toggle(event) {
    this.set_state(event.currentTarget.value);
  }
}
