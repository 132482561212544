// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

require("css/application.scss")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Foundation } from 'foundation-sites'
import $ from 'jquery'

import autosize from "autosize"
global.autosize = autosize


document.addEventListener('turbolinks:load', () => {
  $(document).foundation()
  autosize($('textarea'))
})

require("trix")
require("@rails/actiontext")

require("datatables.net")
require('datatables.net-zf')
// require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
require('datatables.net-zf/css/dataTables.foundation.min.css')

const dataTables = [];

document.addEventListener("turbolinks:load", () => {
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        pageLength: 50,
        stateSave: true
      }));
    });
  }
});

$(document).on( 'init.dt', ( e, settings ) => {
    // var api = new $.fn.dataTable.Api( settings );
    // console.log( 'New DataTable created:', api.table().node() );
    $('input[type=search]').focus();
});

document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});

import "controllers"
