import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['element', 'button']

  connect() {
    if (this.elementTarget.querySelector("input").value) {
      this.buttonTarget.classList.add('hide')
    } else {
      this.elementTarget.classList.add('hide')
    }
    // this.buttonTarget.textContent = ''
  }

  toggle(event) {
    event.preventDefault()
    this.elementTarget.classList.toggle('hide')
    this.buttonTarget.classList.add('hide')
  }
}
