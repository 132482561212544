import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['element', 'checkbox']

  set_state() {
    if (this.checkboxTarget.checked) {
      this.elementTarget.classList.remove('hide')
    } else {
      this.elementTarget.classList.add('hide')
    }
  }

  connect() {
    this.set_state()
  }

  toggle(event) {
    event.preventDefault()
    this.set_state()
  }
}
